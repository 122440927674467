import { trackGA4Event } from '@/helpers/track'

function track(eventName, props) {
  trackGA4Event('Mixpanel', {
    mixpanelEventName: eventName,
    mixpanelEventParams: props,
  })
}

export function useTracking() {
  return {
    trackGA4Event: async (eventName, props) => {
      trackGA4Event(eventName, props)
    },
    trackGA4EventAfterMounted: async (eventName, props) => {
      setTimeout(() => {
        trackGA4Event(eventName, props)
      }, 1000)
    },
    trackAfterMounted: async (eventName, props) => {
      setTimeout(() => {
        track(eventName, props)
      }, 1000)
    },
    track: async (eventName, props) => {
      track(eventName, props)
    },
  }
}
